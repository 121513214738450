import React from 'react';
import './UserBadge.css';
import { IRISAvatar } from 'components/IRISAvatar';

interface IUserBadgeProps {
  profilePicUrl?: string;
  isUser: boolean;
}

const UserBadge: React.FC<IUserBadgeProps> = ({ profilePicUrl, isUser }) => {
  return (
    <div className='badge-container'>
      {isUser ? (
        <img
          src={profilePicUrl}
          alt='User Profile'
          className='profile-pic'
        />
      ) : (
        <div className='iris-avatar-wrapper'>
          <IRISAvatar />
        </div>
      )}
    </div>
  );
};

export default UserBadge;
