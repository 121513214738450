import React, { SVGProps } from 'react';
import { ButtonColors, ButtonState } from './Button.constants';
import './Button.css';

type ButtonProps = {
  buttonState: ButtonState;
  buttonIcon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  title?: string;
  buttonClass?: string;
  textClass?: string;
};

const getButtonColor = (buttonState: ButtonState) => {
  switch (buttonState) {
    case ButtonState.ACTIVE:
      return ButtonColors.ACTIVE;
    case ButtonState.INACTIVE:
      return ButtonColors.INACTIVE;
    case ButtonState.DISABLED:
      return ButtonColors.DISABLED;
    default:
      return ButtonColors.INACTIVE;
  }
};

export const Button = ({
  buttonState,
  onClick,
  title,
  buttonIcon: ButtonIcon,
  buttonClass = '',
  textClass = '',
}: ButtonProps) => {
  const bgColor = getButtonColor(buttonState);
  const isDisabled = buttonState === ButtonState.DISABLED;

  return (
    <button
      onClick={onClick}
      className={`button ${buttonClass} ${buttonState}-hover`}
      style={{
        backgroundColor: bgColor,
      }}
      disabled={isDisabled}
    >
      {ButtonIcon && (
        <ButtonIcon
          height={22.5}
          width={22.5}
          fill={buttonState === ButtonState.ACTIVE ? '#212529' : '#fff'}
        />
      )}
      {title && <div className={`button-text ${textClass}`}>{title}</div>}
    </button>
  );
};
