import type { SVGProps } from 'react';
const Iris = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='4vw'
    height='3vh'
    viewBox='0 0 58 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M0.270092 20.7654C0.0900099 20.7654 0 20.6676 0 20.4721L2.52114 0.527931C2.56116 0.332401 2.67121 0.234635 2.85129 0.234635H3.15142C3.23145 0.234635 3.2915 0.263965 3.33152 0.322625C3.39154 0.381284 3.41155 0.44972 3.39154 0.527931L0.900402 20.4721C0.880393 20.5503 0.84037 20.6187 0.780342 20.6774C0.720315 20.736 0.650288 20.7654 0.570251 20.7654H0.270092Z'
      fill='white'
    />
    <path
      d='M2.37179 20.7654C2.29175 20.7654 2.22171 20.736 2.16168 20.6774C2.12166 20.6187 2.11166 20.5503 2.13167 20.4721L4.65281 0.527931C4.65281 0.44972 4.68283 0.381284 4.74286 0.322625C4.80289 0.263965 4.87292 0.234635 4.95295 0.234635H6.45365C6.65375 0.234635 6.73377 0.332401 6.69376 0.527931L4.2026 20.4721C4.16258 20.6676 4.05255 20.7654 3.87247 20.7654H2.37179Z'
      fill='white'
    />
    <path
      d='M6.00273 20.7654C5.90268 20.7654 5.82268 20.736 5.76265 20.6774C5.70262 20.5992 5.6826 20.5112 5.70261 20.4134L8.19376 0.586591C8.21377 0.488826 8.25378 0.410614 8.3138 0.351954C8.39384 0.273742 8.48389 0.234635 8.58393 0.234635H12.0055C12.1055 0.234635 12.1856 0.273742 12.2456 0.351954C12.3056 0.410614 12.3256 0.488826 12.3056 0.586591L9.81449 20.4134C9.79448 20.5112 9.74445 20.5992 9.66442 20.6774C9.60439 20.736 9.52436 20.7654 9.42431 20.7654H6.00273Z'
      fill='white'
    />
    <path
      d='M24.4877 20.7654C24.3076 20.7654 24.1876 20.6774 24.1275 20.5014L21.5163 12.3771C21.4763 12.2989 21.4163 12.2598 21.3363 12.2598H18.635C18.535 12.2598 18.485 12.3087 18.485 12.4064L17.4645 20.4134C17.4445 20.5112 17.3945 20.5992 17.3144 20.6774C17.2544 20.736 17.1744 20.7654 17.0743 20.7654H13.6527C13.5527 20.7654 13.4727 20.736 13.4126 20.6774C13.3526 20.5992 13.3326 20.5112 13.3526 20.4134L15.8438 0.586591C15.8638 0.488826 15.9038 0.410614 15.9638 0.351954C16.0438 0.273742 16.1339 0.234635 16.2339 0.234635H24.5778C26.2785 0.234635 27.6192 0.70391 28.5996 1.64246C29.5801 2.58101 30.0703 3.84218 30.0703 5.42598C30.0703 5.62151 30.0503 5.95391 30.0102 6.42318C29.8502 7.75279 29.38 8.89665 28.5996 9.85475C27.8192 10.7933 26.8188 11.4581 25.5982 11.8492C25.4782 11.8883 25.4382 11.9567 25.4782 12.0545L28.3895 20.3547C28.4095 20.3939 28.4195 20.4427 28.4195 20.5014C28.4195 20.6774 28.3095 20.7654 28.0894 20.7654H24.4877ZM19.6855 3.78352C19.6455 3.78352 19.6055 3.80307 19.5655 3.84218C19.5454 3.86173 19.5354 3.89106 19.5354 3.93017L18.9052 8.94553C18.9052 9.04329 18.9452 9.09218 19.0252 9.09218H22.837C23.7374 9.09218 24.4877 8.81843 25.088 8.27095C25.6883 7.70391 25.9884 6.98045 25.9884 6.10056C25.9884 5.39665 25.7583 4.83939 25.2981 4.42877C24.8579 3.9986 24.2676 3.78352 23.5273 3.78352H19.6855Z'
      fill='white'
    />
    <path
      d='M31.5027 20.7654C31.4027 20.7654 31.3227 20.736 31.2626 20.6774C31.2026 20.5992 31.1826 20.5112 31.2026 20.4134L33.6937 0.586591C33.7137 0.488826 33.7538 0.410614 33.8138 0.351954C33.8938 0.273742 33.9839 0.234635 34.0839 0.234635H37.5055C37.6055 0.234635 37.6856 0.273742 37.7456 0.351954C37.8056 0.410614 37.8256 0.488826 37.8056 0.586591L35.3145 20.4134C35.2945 20.5112 35.2444 20.5992 35.1644 20.6774C35.1044 20.736 35.0243 20.7654 34.9243 20.7654H31.5027Z'
      fill='white'
    />
    <path
      d='M45.7858 21C43.6048 21 41.884 20.521 40.6234 19.5629C39.3828 18.6047 38.7625 17.3045 38.7625 15.662C38.7625 15.486 38.7825 15.2221 38.8226 14.8701L38.9126 14.2249C38.9326 14.1271 38.9726 14.0489 39.0327 13.9902C39.1127 13.912 39.2027 13.8729 39.3028 13.8729H42.6343C42.7344 13.8729 42.8144 13.912 42.8744 13.9902C42.9345 14.0489 42.9545 14.1271 42.9344 14.2249L42.8744 14.6648C42.7744 15.4469 43.0445 16.1117 43.6848 16.6592C44.3451 17.1872 45.2855 17.4511 46.5061 17.4511C47.6266 17.4511 48.467 17.2067 49.0273 16.7179C49.6075 16.2291 49.8977 15.6327 49.8977 14.9288C49.8977 14.4986 49.7476 14.1369 49.4474 13.8436C49.1673 13.5503 48.7771 13.2961 48.2769 13.081C47.7967 12.8464 47.0263 12.5237 45.9658 12.1131C44.8053 11.683 43.8249 11.2626 43.0245 10.852C42.2241 10.4218 41.5538 9.85475 41.0136 9.15084C40.4733 8.44693 40.2032 7.57682 40.2032 6.5405C40.2032 6.40363 40.2232 6.15922 40.2632 5.80726C40.4833 4.02793 41.3137 2.62011 42.7544 1.5838C44.195 0.527932 45.9759 0 48.0968 0C49.4575 0 50.658 0.234637 51.6985 0.70391C52.759 1.15363 53.5693 1.79888 54.1296 2.63966C54.7099 3.46089 55 4.42877 55 5.54329C55 5.69972 54.98 5.95391 54.94 6.30587L54.8799 6.74581C54.8599 6.84358 54.8099 6.93156 54.7299 7.00978C54.6698 7.06843 54.5898 7.09777 54.4898 7.09777H51.1282C51.0282 7.09777 50.9481 7.06843 50.8881 7.00978C50.8281 6.93156 50.8081 6.84358 50.8281 6.74581L50.8581 6.51117C50.9581 5.68994 50.708 4.99581 50.1078 4.42877C49.5275 3.84218 48.6471 3.54888 47.4665 3.54888C46.4861 3.54888 45.7057 3.77374 45.1255 4.22346C44.5452 4.65363 44.2551 5.25 44.2551 6.01257C44.2551 6.44274 44.3951 6.80447 44.6752 7.09777C44.9554 7.39106 45.3656 7.6648 45.9058 7.91899C46.4661 8.17318 47.3064 8.51536 48.427 8.94553C49.6675 9.43436 50.628 9.85475 51.3083 10.2067C52.0086 10.5391 52.6389 11.0475 53.1992 11.7318C53.7594 12.4162 54.0396 13.2765 54.0396 14.3128C54.0396 14.4497 54.0195 14.6941 53.9795 15.0461C53.7394 16.8841 52.879 18.3408 51.3983 19.4162C49.9377 20.4721 48.0668 21 45.7858 21Z'
      fill='white'
    />
  </svg>
);
export default Iris;
