export enum ButtonColors {
  ACTIVE = '#33EBEB',
  INACTIVE = '#212529',
  DISABLED = '#C3C3C3',
  ACTIVE_HOVER = '#212529',
  INACTIVE_HOVER = '#FF5656',
}

export enum ButtonState {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DISABLED = 'disabled',
}
