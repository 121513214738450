import React, { useEffect, useRef } from 'react';
import './Transcript.css';
import { useTranslation } from 'react-i18next';
import { useWebSocketContext } from 'store/webSocketContext';
import { useAppSelector } from 'store/hooks';
import { VoiceAnimation } from 'components/VoiceAnimation';

interface ITranscriptProps {
  // isIframe: boolean;
}

const Transcript: React.FC<ITranscriptProps> = () => {
  const { messages, isWebSocketClosed, isIframe } = useWebSocketContext();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { showMicOverlay } = useAppSelector(
    (state) => state.interactionModeConfig,
  );
  const { screenShareConsented } = useWebSocketContext();

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <>
      {!screenShareConsented && (
        <div className='voice-animation'>
          <VoiceAnimation />
        </div>
      )}

      <div
        className={`${screenShareConsented ? 'parent-screen' : 'parent-container'}`}
      >
        <div
          className={`full-conversation-container ${
            screenShareConsented ? 'screen-sharing' : ''
          }`}
        >
          <div
            className={`${screenShareConsented ? 'screen-container' : 'conversation-container'}`}
            ref={scrollRef}
          >
            <ul className='transcript-text'>
              <li className='empty-message' />
              {messages.map((message, index) => (
                <li
                  key={index}
                  className={
                    message.role === 'user' ? 'user-messages' : 'iris-messages'
                  }
                >
                  {message.role === 'user' ? 'You' : 'Iris'}: {message.content}
                </li>
              ))}
              <li className='empty-message' />
            </ul>
          </div>
        </div>
      </div>
      {isIframe && showMicOverlay && <div className='mic-off-overlay'></div>}
      {isWebSocketClosed && isIframe && (
        <div className='websocket-closed-overlay'>
          <div className='websocket-closed-message'>
            {t('IFrame.overlay_text1')} <br />
            {t('IFrame.overlay_text2')}
            <br />
            {t('IFrame.overlay_text3')}
          </div>
          <button
            className='contact-us-button'
            onClick={() => {
              window.open(
                'https://www.auberginesolutions.com/contact/',
                '_blank',
                'noopener,noreferrer',
              );
            }}
          >
            {t('IFrame.contact_us')}
          </button>
        </div>
      )}
    </>
  );
};

export default Transcript;
