/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { toggleIrisState, toggleScreenState } from 'store/InteractionModeSlice';
import { findDelimiter } from 'utils/audioUtils';

interface WebSocketContextProps {
  messages: IMessage[];
  isWebSocketClosed: boolean;
  sendMessage: (message: string | Blob) => void;
  videoRef: React.RefObject<HTMLVideoElement>;
  screenRef: React.RefObject<HTMLVideoElement>;
  isSpeaking: boolean;
  cameraError: string | null;
  screenError: string | null;
  screenShareConsented: boolean;
  isIframe: boolean;
}

interface IMessage {
  role: string;
  content: string;
  start?: number;
  duration?: number;
  is_final?: boolean;
  conversationId?: number; // Added to track conversation turns
}

const WebSocketContext = createContext<WebSocketContextProps | undefined>(
  undefined,
);

export const WebSocketProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const isIframe = window.location.pathname === '/iframe';

  const dispatch = useAppDispatch();
  const { micOn, cameraOn, screenOn } = useAppSelector(
    (state) => state.interactionModeConfig,
  );

  const [messages, setMessages] = useState<IMessage[]>([]);
  const [screenShareConsented, setScreenShareConsented] = useState(false);

  const [isWebSocketClosed, setIsWebSocketClosed] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [cameraError, setCameraError] = useState<string | null>(null);
  const [screenError, setScreenError] = useState<string | null>(null);
  const socketRef = useRef<WebSocket | null>(null);
  const messageQueue = useRef<(string | Blob)[]>([]);
  const mediaSource = useRef<MediaSource>(new MediaSource());
  const audioPlayer = useRef<HTMLAudioElement | null>(null);
  const sourceBuffer = useRef<SourceBuffer | null>(null);
  const queue = useRef<Uint8Array[]>([]);
  const delimiter = useRef<Uint8Array>(
    new Uint8Array([0x1f, 0x8b, 0x08, 0x00]),
  );
  const currentSpeechId = useRef<any | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const screenRef = useRef<HTMLVideoElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const cameraStreamRef = useRef<MediaStream | null>(null);
  const screenStreamRef = useRef<MediaStream | null>(null);
  const cameraIntervalId = useRef<NodeJS.Timeout | null>(null);
  const screenIntervalId = useRef<NodeJS.Timeout | null>(null);

  const TIMEOUT_DURATION = 3000;

  let endOfStreamTimeout: NodeJS.Timeout;

  useEffect(() => {
    const connectWebSocket = () => {
      const wsUrl = isIframe
        ? `${process.env.REACT_APP_IFRAME_WEB_SOCKET_URL}`
        : `${process.env.REACT_APP_WEB_SOCKET_URL}${localStorage.getItem('accessToken')}/`;

      console.log('Connecting to WebSocket URL:', wsUrl); // Log the WebSocket URL

      const newSocket = new WebSocket(wsUrl);
      newSocket.binaryType = 'arraybuffer';

      newSocket.onopen = () => {
        console.log('WebSocket connected');
      };

      newSocket.onmessage = (message: MessageEvent) => {
        if (message.data instanceof ArrayBuffer) {
          handleAudioMessage(message.data);
        } else {
          const data = JSON.parse(message.data);
          if (data.event === 'conversation_end') {
            setIsWebSocketClosed(true);
          } else {
            handleTextMessage(data);
          }
        }
      };

      newSocket.onclose = (event) => {
        console.log('WebSocket closed', event);
      };

      newSocket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      socketRef.current = newSocket;

      return () => {
        newSocket.close();
      };
    };

    const initMedia = () => {
      audioPlayer.current = new Audio();
      audioPlayer.current.src = URL.createObjectURL(mediaSource.current);
      mediaSource.current.addEventListener('sourceopen', onSourceOpen);
    };

    connectWebSocket();
    initMedia();
  }, []);

  const onSourceOpen = () => {
    try {
      if (!sourceBuffer.current) {
        sourceBuffer.current =
          mediaSource.current.addSourceBuffer('audio/mpeg');
        sourceBuffer.current.mode = 'sequence';
        sourceBuffer.current.addEventListener('updateend', processQueue);
      }
      processQueue();
    } catch (error) {
      console.error('Failed to add SourceBuffer:', error);
    }
  };

  const startEndOfStreamTimer = () => {
    if (endOfStreamTimeout) clearTimeout(endOfStreamTimeout);
    endOfStreamTimeout = setTimeout(() => {
      console.log('No new data received, signaling end of stream');
      mediaSource.current.endOfStream();
    }, TIMEOUT_DURATION);
  };

  const processQueue = () => {
    if (
      queue.current.length > 0 &&
      sourceBuffer.current &&
      !sourceBuffer.current.updating
    ) {
      try {
        const audioChunk = queue.current.shift();
        console.log('Processing audio chunk:', audioChunk);
        if (sourceBuffer.current && audioChunk) {
          sourceBuffer.current.appendBuffer(audioChunk);
        }
        // Set Iris state to true before playing
        dispatch(toggleIrisState(true));

        // Ensure any previous onended event is removed before attaching a new one
        audioPlayer.current!.onended = null;

        audioPlayer
          .current!.play()
          .then(() => {
            console.log('Audio playback started');

            // Attach onended event listener
            audioPlayer.current!.onended = () => {
              console.log('Audio playback ended');
              if (queue.current.length === 0) {
                console.log('Queue is empty, setting Iris state to false');
                dispatch(toggleIrisState(false));
              } else {
                processQueue();
              }
            };

            startEndOfStreamTimer(); // Start the timer

            // Reset timer when new data arrives
            sourceBuffer.current?.addEventListener(
              'updateend',
              startEndOfStreamTimer,
            );
          })
          .catch((error) => {
            console.error('Error playing audio:', error);
            // If there's an error, set Iris state back to false
            dispatch(toggleIrisState(false));
          });
      } catch (error) {
        console.error('Error appending to SourceBuffer:', error);
        dispatch(toggleIrisState(false));
      }
    } else {
      console.log('Queue is empty or SourceBuffer is updating');
    }
  };

  const handleAudioMessage = (messageData: ArrayBuffer) => {
    const data = new Uint8Array(messageData);
    const delimiterIndex = findDelimiter(data, delimiter.current);
    if (delimiterIndex >= 0) {
      const metadataBytes = data.slice(0, delimiterIndex);
      const audioChunk = data.slice(delimiterIndex + delimiter.current.length);

      const metadataString = new TextDecoder().decode(metadataBytes);
      const metadata = JSON.parse(metadataString);

      if (metadata.speech_id !== currentSpeechId.current) {
        currentSpeechId.current = metadata.speech_id;
        resetSourceBuffer();
      }

      queue.current.push(audioChunk);
      processQueue();
    } else {
      console.error('Delimiter not found in the received data');
    }
  };

  const resetSourceBuffer = () => {
    if (sourceBuffer.current) {
      mediaSource.current.removeSourceBuffer(sourceBuffer.current);
      sourceBuffer.current = null;
    }

    mediaSource.current = new MediaSource();
    mediaSource.current.addEventListener('sourceopen', onSourceOpen);
    audioPlayer.current!.src = URL.createObjectURL(mediaSource.current);
    audioPlayer.current!.pause();
    audioPlayer.current!.currentTime = 0;
    queue.current = [];
  };

  const stopCurrentAudio = () => {
    if (audioPlayer.current) {
      audioPlayer.current.pause();
      audioPlayer.current.onended = null;
    }
    dispatch(toggleIrisState(false));
    queue.current = [];
  };

  const handleTextMessage = (data: IMessage) => {
    if (data.hasOwnProperty('role')) {
      if (data.role === 'user') {
        stopCurrentAudio();
      }
      addMessage(data);
    }
  };

  const addMessage = (messageData: IMessage) => {
    setMessages((prevMessages) => {
      if (prevMessages.length === 0) {
        return [
          {
            ...messageData,
            is_final: messageData.is_final ?? true,
            conversationId: 1,
          },
        ];
      }

      const lastMessage = prevMessages[prevMessages.length - 1];

      // Helper function to deduplicate content
      const deduplicateContent = (
        existingContent: string,
        newContent: string,
      ): string => {
        const existingWords = existingContent.trim().split(/\s+/);
        const newWords = newContent.trim().split(/\s+/);

        let startIndex = -1;
        for (let i = 0; i < existingWords.length; i++) {
          if (existingWords[i] === newWords[0]) {
            let matches = true;
            for (
              let j = 0;
              j < newWords.length && i + j < existingWords.length;
              j++
            ) {
              if (existingWords[i + j] !== newWords[j]) {
                matches = false;
                break;
              }
            }
            if (matches) {
              startIndex = i;
              break;
            }
          }
        }

        if (startIndex === -1) {
          return `${existingContent.trim()} ${newContent.trim()}`;
        } else {
          const overlapLength = Math.min(
            newWords.length,
            existingWords.length - startIndex,
          );
          const uniqueWords = newWords.slice(overlapLength);
          return uniqueWords.length > 0
            ? `${existingContent.trim()} ${uniqueWords.join(' ')}`
            : existingContent.trim();
        }
      };

      // Handle user messages
      if (messageData.role === 'user') {
        // Check if the last message was from the assistant
        if (lastMessage.role === 'assistant') {
          // Start a new conversation turn
          const newConversationId = (lastMessage.conversationId || 0) + 1;
          return [
            ...prevMessages,
            {
              ...messageData,
              conversationId: newConversationId,
              is_final: messageData.is_final ?? false,
            },
          ];
        }

        // If the last message was from the user and part of the same conversation
        if (
          lastMessage.role === 'user' &&
          lastMessage.conversationId ===
            prevMessages[prevMessages.length - 1].conversationId
        ) {
          return [
            ...prevMessages.slice(0, -1),
            {
              ...lastMessage,
              content: deduplicateContent(
                lastMessage.content,
                messageData.content,
              ),
              is_final: messageData.is_final ?? false,
            },
          ];
        }

        // If it's a new user message
        const newConversationId = (lastMessage.conversationId || 0) + 1;
        return [
          ...prevMessages,
          {
            ...messageData,
            conversationId: newConversationId,
            is_final: messageData.is_final ?? false,
          },
        ];
      }

      // Handle assistant messages
      if (messageData.role === 'assistant') {
        if (
          lastMessage.role === 'assistant' &&
          lastMessage.conversationId ===
            prevMessages[prevMessages.length - 1].conversationId
        ) {
          return [
            ...prevMessages.slice(0, -1),
            {
              ...lastMessage,
              content: deduplicateContent(
                lastMessage.content,
                messageData.content,
              ),
              is_final: messageData.is_final ?? lastMessage.is_final,
              conversationId: lastMessage.conversationId,
            },
          ];
        }
        return [
          ...prevMessages,
          {
            ...messageData,
            conversationId: lastMessage.conversationId,
            is_final: messageData.is_final ?? true,
          },
        ];
      }

      // Default case
      return [...prevMessages, messageData];
    });
  };
  const sendMessage = (message: string | Blob) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(message);
    } else {
      console.log('WebSocket is not open. Queueing message.');
      messageQueue.current.push(message);
    }
  };

  const startAudioRecording = async () => {
    if (!micOn || streamRef.current) return;

    try {
      streamRef.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const recorder = new MediaRecorder(streamRef.current);

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          sendMessage(event.data);
        }
      };

      recorder.start(250);
      mediaRecorderRef.current = recorder;
      setIsSpeaking(true); // Set isSpeaking to true when recording starts
    } catch (error) {
      console.error('Error starting audio recording:', error);
    }
  };

  const stopAudioRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== 'inactive'
    ) {
      mediaRecorderRef.current.stop();
      setIsSpeaking(false); // Set isSpeaking to false when recording stops
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
  };

  const startCamera = async () => {
    if (!cameraOn) return;

    try {
      cameraStreamRef.current = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: false,
      });
      if (videoRef.current && cameraStreamRef.current) {
        videoRef.current.srcObject = cameraStreamRef.current;
        videoRef.current.onloadedmetadata = () => {
          videoRef.current?.play();
        };
      }
      setCameraError(null);

      // Send message to WebSocket that camera is on
      sendMessage(JSON.stringify({ data_type: 'camera', content: 'on' }));

      // Start sending camera frames
      cameraIntervalId.current = setInterval(() => {
        captureAndSendFrame('camera');
      }, 2000); // Adjust interval as needed
    } catch (err) {
      console.error('Error accessing camera:', err);
      setCameraError('Camera access denied. Please check your permissions.');
    }
  };

  const stopCamera = () => {
    if (cameraStreamRef.current) {
      cameraStreamRef.current.getTracks().forEach((track) => track.stop());
      cameraStreamRef.current = null;
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
    if (cameraIntervalId.current) {
      clearInterval(cameraIntervalId.current);
      cameraIntervalId.current = null;
    }

    // Send message to WebSocket that camera is off
    sendMessage(JSON.stringify({ data_type: 'camera', content: 'off' }));
  };

  const startScreenShare = async () => {
    if (!screenOn || screenStreamRef.current) return;

    try {
      screenStreamRef.current = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: false,
      });

      // User has consented to share screen
      setScreenShareConsented(true);

      if (screenRef.current && screenStreamRef.current) {
        screenRef.current.srcObject = screenStreamRef.current;
        screenRef.current.onloadedmetadata = () => {
          screenRef.current?.play();
        };

        screenStreamRef.current.getVideoTracks()[0].onended = () => {
          stopScreenShare();
        };
      }
      setScreenError(null);

      // Send message to WebSocket that screen sharing is on
      sendMessage(JSON.stringify({ data_type: 'screen', content: 'on' }));

      // Start sending screen frames
      screenIntervalId.current = setInterval(() => {
        captureAndSendFrame('screen');
      }, 1000); // Adjust interval as needed
    } catch (err) {
      console.error('Error accessing screen share:', err);
      setScreenError(
        'Screen share access denied. Please check your permissions.',
      );
      setScreenShareConsented(false);
      dispatch(toggleScreenState(!screenOn));
    }
  };
  const stopScreenShare = () => {
    if (screenStreamRef.current) {
      screenStreamRef.current.getTracks().forEach((track) => track.stop());
      screenStreamRef.current = null;
    }
    if (screenRef.current) {
      screenRef.current.srcObject = null;
    }
    if (screenIntervalId.current) {
      clearInterval(screenIntervalId.current);
      screenIntervalId.current = null;
    }

    // Reset screen share consent
    setScreenShareConsented(false);

    // Send message to WebSocket that screen sharing is off
    sendMessage(JSON.stringify({ data_type: 'screen', content: 'off' }));
  };

  const captureAndSendFrame = (visionType: 'camera' | 'screen') => {
    const sourceStream =
      visionType === 'camera'
        ? cameraStreamRef.current
        : screenStreamRef.current;

    if (!sourceStream) {
      console.error('No media stream available for vision.');
      return;
    }

    const videoElement =
      visionType === 'camera' ? videoRef.current : screenRef.current;

    if (!sourceStream || !videoElement) return;

    const canvas = document.createElement('canvas');
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
    const context = canvas.getContext('2d');

    if (context) {
      context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        if (blob) {
          const reader = new FileReader();
          reader.onload = () => {
            const base64Image = reader.result?.toString().split(',')[1];
            if (base64Image) {
              sendMessage(
                JSON.stringify({
                  data_type: 'vision',
                  media_source: visionType,
                  content: base64Image,
                }),
              );
            }
          };
          reader.readAsDataURL(blob);
        }
      }, 'image/jpeg');
    }
  };

  useEffect(() => {
    if (micOn) {
      startAudioRecording();
    } else {
      stopAudioRecording();
    }
  }, [micOn]);

  useEffect(() => {
    if (cameraOn) {
      startCamera();
    } else {
      stopCamera();
    }
  }, [cameraOn]);

  useEffect(() => {
    if (screenOn) {
      startScreenShare();
    } else {
      stopScreenShare();
    }
  }, [screenOn]);

  return (
    <WebSocketContext.Provider
      value={{
        messages,
        screenShareConsented,
        isWebSocketClosed,
        sendMessage,
        isIframe,
        videoRef,
        screenRef,
        isSpeaking,
        cameraError,
        screenError,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocketContext = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error(
      'useWebSocketContext must be used within a WebSocketProvider',
    );
  }
  return context;
};
