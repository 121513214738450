import React, { useEffect, useMemo } from 'react';
import './Footer.css';
import { Button } from 'components/common/Button';
import {
  AubLogo,
  CameraOffIcon,
  CameraOnIcon,
  MicOffIcon,
  MicOnIcon,
  ScreenOffIcon,
  ScreenOnIcon,
} from 'components/Icons';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ButtonState } from 'components/common/Button/Button.constants';
import {
  toggleCameraState,
  toggleMicState,
  toggleScreenState,
} from 'store/InteractionModeSlice';
import { InteractionModes } from 'constants/common';
import { useWebSocketContext } from 'store/webSocketContext';

interface IFooterProps {
  interactionMode: InteractionModes;
}

const Footer: React.FC<IFooterProps> = ({ interactionMode }) => {
  const { micOn, cameraOn, screenOn } = useAppSelector(
    (state) => state.interactionModeConfig,
  );
  const dispatch = useAppDispatch();
  const { screenShareConsented } = useWebSocketContext();

  useEffect(() => {
    if (!micOn && (cameraOn || screenOn)) {
      dispatch(toggleCameraState(false));
      dispatch(toggleScreenState(false));
    }
  }, [micOn, cameraOn, screenOn, dispatch]);

  const buttonStates = useMemo(() => {
    const isCameraOptionAvailable =
      interactionMode !== InteractionModes.VOICE_ONLY;
    const isScreenShareOptionAvailable =
      interactionMode === InteractionModes.SCREEN_SHARING;

    return {
      mic: {
        icon: micOn ? MicOnIcon : MicOffIcon,
        state: micOn ? ButtonState.ACTIVE : ButtonState.INACTIVE,
      },
      camera: {
        icon: cameraOn ? CameraOnIcon : CameraOffIcon,
        state:
          micOn && isCameraOptionAvailable
            ? cameraOn
              ? ButtonState.ACTIVE
              : ButtonState.INACTIVE
            : ButtonState.DISABLED,
      },
      screenShare: {
        icon: screenOn ? ScreenOnIcon : ScreenOffIcon,
        state:
          micOn && isScreenShareOptionAvailable
            ? screenOn
              ? ButtonState.ACTIVE
              : ButtonState.INACTIVE
            : ButtonState.DISABLED,
      },
      showCamera: isCameraOptionAvailable,
      showScreenShare: isScreenShareOptionAvailable,
    };
  }, [micOn, cameraOn, screenOn, interactionMode]);

  const toggleMic = () => {
    dispatch(toggleMicState(!micOn));
  };

  const toggleCamera = () => {
    if (micOn && buttonStates.showCamera) {
      dispatch(toggleCameraState(!cameraOn));
    }
  };

  const toggleScreen = () => {
    if (micOn && buttonStates.showScreenShare) {
      dispatch(toggleScreenState(!screenOn));
    }
  };

  return (
    <footer className='d-flex flex-column  align-items-center position-relative'>
      <div
        className={`d-flex flex-row gap-2 flex-fill justify-content-center ${screenShareConsented ? 'button-screen-container' : 'button-container'}`}
      >
        <Button
          onClick={toggleMic}
          buttonIcon={buttonStates.mic.icon}
          buttonState={buttonStates.mic.state}
        />
        {buttonStates.showCamera && (
          <Button
            onClick={toggleCamera}
            buttonIcon={buttonStates.camera.icon}
            buttonState={buttonStates.camera.state}
          />
        )}
        {buttonStates.showScreenShare && (
          <Button
            onClick={toggleScreen}
            buttonIcon={buttonStates.screenShare.icon}
            buttonState={buttonStates.screenShare.state}
          />
        )}
      </div>
      <div
        className={`${screenShareConsented ? 'logo-screen-container' : 'logo-container'}`}
      >
        <AubLogo />
      </div>
    </footer>
  );
};

export default Footer;
