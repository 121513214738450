import { createSlice } from '@reduxjs/toolkit';

const InteractionModeSlice = createSlice({
  name: 'interactionMode',
  initialState: {
    micOn: false,
    cameraOn: false,
    screenOn: false,
    isIrisSpeaking: false,
    showMicOverlay: true,
  },
  reducers: {
    toggleMicState(state, action) {
      state.micOn = action.payload;
      if (action.payload && state.showMicOverlay) {
        state.showMicOverlay = false; // Hide overlay after first mic activation
      }
    },
    toggleCameraState(state, action) {
      state.cameraOn = action.payload;
    },
    toggleScreenState(state, action) {
      state.screenOn = action.payload;
    },
    toggleIrisState(state, action) {
      state.isIrisSpeaking = action.payload;
    },
  },
});

export const {
  toggleMicState,
  toggleCameraState,
  toggleScreenState,
  toggleIrisState,
} = InteractionModeSlice.actions;
export default InteractionModeSlice.reducer;
