import React from 'react';
import './UserCameraView.css';
import { useWebSocketContext } from 'store/webSocketContext';

const UserCameraView: React.FC = () => {
  const { videoRef, isSpeaking, cameraError } = useWebSocketContext();

  return (
    <div className='video-container'>
      {cameraError && <div className='error-message'>{cameraError}</div>}
      <video
        ref={videoRef}
        autoPlay
        playsInline
        muted
        className='video-feed'
      />
      <div className={`user-info ${isSpeaking ? 'speaking' : ''}`}>
        {isSpeaking && (
          <div className='audio-indicator'>
            <div className='bar'></div>
            <div className='bar'></div>
            <div className='bar'></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserCameraView;
