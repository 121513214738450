import React from 'react';
import './IRISAvatar.css';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';

interface IIRISAvatarProps {
  // isIrisSpeaking: boolean;
}

const IRISAvatar: React.FC<IIRISAvatarProps> = () => {
  const { t } = useTranslation();
  const { isIrisSpeaking } = useAppSelector(
    (state) => state.interactionModeConfig,
  );

  return (
    <div className='avatar-container d-flex align-items-center justify-content-center'>
      <div className={`avatar ${isIrisSpeaking ? 'speaking' : ''}`}>
        <span className='iris-text'>{t('IRISAvatar.Iris')}</span>
        {isIrisSpeaking && (
          <div className='bubble-container'>
            <div className='bubble'></div>
            <div className='bubble'></div>
            <div className='bubble'></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default IRISAvatar;
