import React from 'react';
import { Button } from '../common/Button';
import { ButtonState } from '../common/Button/Button.constants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { toggleScreenState } from 'store/InteractionModeSlice';
import './Header.css';
import { ScreenOffIcon } from 'components/Icons';
import { useTranslation } from 'react-i18next';

const Header: React.FC = () => {
  const { t } = useTranslation();

  const { screenOn } = useAppSelector((state) => state.interactionModeConfig);

  const dispatch = useAppDispatch();

  const toggleScreen = () => {
    dispatch(toggleScreenState(!screenOn));
  };
  return (
    <header className='header'>
      <p className='header-text'>{t('Header.Notification')} </p>
      <Button
        buttonClass='header-button'
        textClass='header-button-text'
        title='Stop Sharing'
        buttonState={ButtonState.INACTIVE}
        onClick={toggleScreen}
        buttonIcon={ScreenOffIcon}
      />
    </header>
  );
};

export default Header;
