import { Transcript } from 'components/Transcript';
import { UserBadge } from 'components/UserBadge';
import { InteractionModes } from 'constants/common';
import { LandingPage } from 'pages/LandingPage';
import React from 'react';
import './ScreenSharingLayout.css';
import { useAppSelector } from 'store/hooks';
import { useWebSocketContext } from 'store/webSocketContext';

const ScreenSharingLayout: React.FC = () => {
  const { cameraOn, screenOn } = useAppSelector(
    (state) => state.interactionModeConfig,
  );
  const authResponse = JSON.parse(localStorage.getItem('authResponse') || '{}');
  const { screenShareConsented } = useWebSocketContext();

  return (
    <LandingPage interactionMode={InteractionModes.SCREEN_SHARING}>
      {screenShareConsented && (
        <>
          <div
            className={` ${cameraOn ? 'badges-cam-on' : 'badges-container'}`}
          >
            {screenOn && (
              <div className='iris-badge'>
                <UserBadge isUser={false} />
              </div>
            )}
            {screenOn && !cameraOn && (
              <div className='user-badge'>
                <UserBadge
                  profilePicUrl={authResponse.picture}
                  isUser={true}
                />
              </div>
            )}
          </div>
        </>
      )}
      <div
        className={`${screenShareConsented ? 'transcript-screen-share' : ''}`}
      >
        <Transcript />
      </div>
    </LandingPage>
  );
};

export default ScreenSharingLayout;
