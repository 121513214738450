import { Navigate, Outlet } from 'react-router-dom';
import { RoutePaths } from 'constants/common';

const Protected = () => {
  const token = localStorage.getItem('accessToken');
  if (!token) {
    return (
      <Navigate
        to={RoutePaths.LOGIN}
        replace
      />
    );
  }

  return <Outlet />;
};

export default Protected;
