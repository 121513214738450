import React from 'react';
import { Transcript } from 'components/Transcript';
import { LandingPage } from 'pages/LandingPage';
import { InteractionModes } from 'constants/common';
import { useWebSocketContext } from 'store/webSocketContext';

const VoiceOnlyLayout: React.FC = () => {
  const { isIframe } = useWebSocketContext();

  return (
    <LandingPage
      interactionMode={
        isIframe ? InteractionModes.VOICE_ONLY : InteractionModes.SCREEN_SHARING
      }
    >
      <div>
        <Transcript />
      </div>
    </LandingPage>
  );
};

export default VoiceOnlyLayout;
