import { RouteObject } from 'react-router-dom';

import Protected from './Protected';
import { RoutePaths } from 'constants/common';
import { VoiceOnlyLayout } from 'pages/VoiceOnlyLayout';
import ScreenSharingLayout from 'pages/ScreenSharingLayout/ScreenSharingLayout';

const protectedRoutes: RouteObject[] = [
  {
    element: <Protected />,
    children: [
      {
        path: RoutePaths.VOICE,
        element: <VoiceOnlyLayout />,
      },
      {
        path: RoutePaths.VISION,
        element: <ScreenSharingLayout />,
      },
    ],
  },
];

export default protectedRoutes;
