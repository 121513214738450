//Searches for a delimiter
export const findDelimiter = (
  data: Uint8Array,
  delimiter: Uint8Array,
): number => {
  for (let i = 0; i < data.length - delimiter.length + 1; i++) {
    let matched = true;
    for (let j = 0; j < delimiter.length; j++) {
      if (data[i + j] !== delimiter[j]) {
        matched = false;
        break;
      }
    }
    if (matched) {
      return i;
    }
  }
  return -1;
};
