import React from 'react';
import './UserScreenView.css';
import { useWebSocketContext } from 'store/webSocketContext';

export const UserScreenView: React.FC = () => {
  const { screenRef, screenError } = useWebSocketContext();

  return (
    <div className='screen-share-container'>
      <video
        ref={screenRef}
        autoPlay
        playsInline
        className='screen-share-feed'
      />
      {screenError && <div className='error-message'>{screenError}</div>}
    </div>
  );
};
