/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

declare global {
  interface Window {
    google: any;
    google_auth_callback_function: (data: {
      credential: string;
    }) => Promise<void>;
  }
}

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.google) {
        window.google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          callback: window.google_auth_callback_function,
        });
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    window.google_auth_callback_function = async (data: {
      credential: string;
    }) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN}/api/v1/google_oauth_callback/?code=${data.credential}`,
          {
            method: 'GET',
          },
        );

        response.json().then((data) => {
          console.log('Backend response:', data);
          localStorage.setItem('authResponse', JSON.stringify(data));

          localStorage.setItem('accessToken', data?.token);
        });

        if (!response.ok) {
          navigate('/not_allowed');
        } else {
          navigate('/vision');
        }
      } catch (error) {
        console.error('Error during Google authentication:', error);
        navigate('/not_allowed');
      }
    };
  }, []);

  return (
    <div className='d-flex flex-grow-1 flex-column min-vh-100 align-items-center justify-content-center'>
      <h1>{t('LoginPage.Welcome')}</h1>
      <div
        id='g_id_onload'
        data-client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        data-context='signin'
        data-callback='google_auth_callback_function'
        data-auto_select='true'
        data-close_on_tap_outside='false'
        data-itp_support='true'
      ></div>
      {/* <div id='googleSignInDiv'></div> */}
    </div>
  );
};

export default LoginPage;
