import React, { ReactNode } from 'react';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { InteractionModes } from 'constants/common';
import { useAppSelector } from 'store/hooks';
import { UserCameraView } from 'components/UserCameraView';
import { UserScreenView } from 'components/UserScreenView';
import './LandingPage.css';
import { useWebSocketContext } from 'store/webSocketContext';

interface ILandingPageProps {
  children: ReactNode;
  interactionMode: InteractionModes;
}

const LandingPage: React.FC<ILandingPageProps> = ({
  children,
  interactionMode,
}) => {
  const { cameraOn, screenOn } = useAppSelector(
    (state) => state.interactionModeConfig,
  );
  const { screenShareConsented } = useWebSocketContext();

  return (
    <div
      className={`container-main d-flex flex-column min-vh-100 ${screenShareConsented ? 'screen-on-bg' : 'voice-bg'}`}
    >
      {screenShareConsented && (
        <div>
          <Header />
        </div>
      )}
      <main className='flex-grow-1 d-flex justify-content-center align-items-center text-center'>
        <div className='w-100'>{children}</div>
      </main>
      {cameraOn ? (
        <div
          className={`${screenShareConsented ? 'cam-screen-sharing' : 'user-camera'}`}
        >
          <UserCameraView />
        </div>
      ) : null}
      {screenOn ? (
        <div
          className={`${screenShareConsented ? 'user-screen' : 'screen-off'}`}
        >
          <UserScreenView />
        </div>
      ) : null}
      <Footer interactionMode={interactionMode} />
    </div>
  );
};

export default LandingPage;
