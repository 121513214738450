import { Provider } from 'react-redux';
import store from 'store';
import { RouterProvider } from 'react-router-dom';
import { router } from 'routes';
import i18n from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import translations from './locales/en.json';
import { WebSocketProvider } from 'store/webSocketContext';

// Initialize i18n
i18n.use(initReactI18next).init({
  resources: translations,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <WebSocketProvider>
          <RouterProvider router={router} />
        </WebSocketProvider>
      </Provider>
    </I18nextProvider>
  );
}

export default App;
