import { RouteObject } from 'react-router-dom';

import { RoutePaths } from 'constants/common';
import { LoginPage } from 'pages/LoginPage';
import { VoiceOnlyLayout } from 'pages/VoiceOnlyLayout';

const publicRoutes: RouteObject[] = [
  {
    path: RoutePaths.LOGIN,
    element: <LoginPage />,
  },
  {
    path: RoutePaths.IFRAME,
    element: <VoiceOnlyLayout />,
  },
  {
    path: RoutePaths.NOT_FOUND,
    element: <div>404 Not Found</div>,
  },
];

export default publicRoutes;
