export enum InteractionModes {
  VOICE_ONLY = 'voiceOnly',
  CAM_ON = 'camOn',
  SCREEN_SHARING = 'screenSharing',
}
export enum RoutePaths {
  VOICE = '/voice',
  IFRAME = '/iframe',
  VISION = '/vision',
  LOGIN = '/',
  NOT_FOUND = '*',
}
