import React from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import waveAnimation from '../../animations/wave.json';
import irisAnimation from '../../animations/IRIS.json';
import './VoiceAnimation.css';
import { Iris } from 'components/Icons';

export const VoiceAnimation = () => {
  return (
    <div className='voice-animation-container'>
      <DotLottieReact
        data={waveAnimation}
        loop
        autoplay
        className='wave-animation'
      />
      <DotLottieReact
        data={irisAnimation}
        loop
        autoplay
        className='iris-animation'
      />
      <div className='icon-wrapper'>
        <Iris />
      </div>
    </div>
  );
};
